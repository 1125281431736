import {
  defaultCRAFormFields,
  defaultCRAFormIntro,
} from './cra-default-translations';

export const translationsData = {
  en: {
    metaData: {
      layout: 'docs',
      'meta-title': 'Plaid Check questions or concerns',
      'meta-description': 'Plaid Check questions or concerns',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    craEntityName: 'Plaid Check',
    pageHeader: 'Plaid Check questions or concerns',
    introHeader: 'Introduction',
    requestType: 'Other',
    intro: `<p>Complete this form to submit questions about your Plaid Check consumer file. For questions about any other Plaid Inc. services, please visit the <a href="https://support-my.plaid.com/hc/en-us">Plaid Inc. Consumer Help Center</a>.</p>
            <p>${defaultCRAFormIntro}</p>`,
    ...defaultCRAFormFields,
    requestDetails: {
      label: 'Additional information about your request',
      required: true,
    },
  },
};
