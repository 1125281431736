import {
  defaultCRAFormFields,
  defaultCRAFormIntro,
} from './cra-default-translations';

export const translationsData = {
  en: {
    metaData: {
      layout: 'docs',
      'meta-title': 'Remove a security freeze',
      'meta-description': 'Remove a security freeze',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    craEntityName: 'Plaid Check',
    pageHeader: 'Remove a security freeze',
    introHeader: 'Introduction',
    requestType: 'Remove security freeze',
    intro: `<p>Complete this form to request to remove a security freeze that you have previously placed on your Plaid Check consumer file. In order to remove a security freeze, you must provide the Personal Identification Number (PIN) that was provided by email when you originally placed the freeze. </p>
            <p>${defaultCRAFormIntro}</p>`,
    ...defaultCRAFormFields,
    pin: {
      label:
        'PIN (4-digit code provided via email after you requested a security freeze)',
      error: 'Invalid PIN',
    },
  },
};
