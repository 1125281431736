import {
  defaultCRAFormFields,
  defaultCRAFormIntro,
} from './cra-default-translations';

export const translationsData = {
  en: {
    metaData: {
      layout: 'docs',
      'meta-title': 'Report fraud or ID theft',
      'meta-description': 'Report fraud or ID theft',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    craEntityName: 'Plaid Check',
    pageHeader: 'Report fraud or ID theft',
    introHeader: 'Introduction',
    requestType: 'Report fraud or ID theft',
    intro: `<p>Complete this form to report that your identity has been subject to fraud or identity theft. If we are able to verify your request, Plaid Check will block Plaid Check consumer reports from being shared with third parties while your request is active. </p>
            <p>${defaultCRAFormIntro}</p>`,
    ...defaultCRAFormFields,
    requestDetails: {
      label:
        'Please provide additional information about your fraud or identity theft concerns',
      required: true,
    },
  },
};
