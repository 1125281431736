import {
  defaultCRAFormFields,
  defaultCRAFormIntro,
} from './cra-default-translations';

export const translationsData = {
  en: {
    metaData: {
      layout: 'docs',
      'meta-title': 'Request a copy of my consumer file',
      'meta-description': 'Request a copy of your consumer file',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
      hasCanonicalTag: false,
    },
    craEntityName: 'Plaid Check',
    pageHeader: 'Request a copy of my consumer file',
    introHeader: 'Introduction',
    requestType: 'Request copy of consumer file',
    intro: `<p>Complete this form to request a copy of your Plaid Check consumer file. If you are seeking access to your personal data associated with any Plaid Inc. services, please use this <a href="https://plaid.com/legal/data-protection-request-form/">Privacy Request Form</a> instead.</p>

<p>${defaultCRAFormIntro}</p>`,
    ...defaultCRAFormFields,
  },
};
