import {
  defaultCRAFormFields,
  defaultCRAFormIntro,
} from './cra-default-translations';

export const translationsData = {
  en: {
    metaData: {
      layout: 'docs',
      'meta-title': 'Place a security freeze',
      'meta-description': 'Place a security freeze',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    craEntityName: 'Plaid Check',
    pageHeader: 'Place a security freeze',
    requestType: 'Place security freeze',
    introHeader: 'Introduction',
    intro: `<p>Complete this form to request to place a security freeze on your Plaid Check consumer file. A security freeze is designed to prevent credit, loans and services from being approved in your name without your consent, but may also delay or interfere with or prohibit the timely approval of any subsequent requests or application you make regarding new credit, loans or services. When you add a security freeze, you will be provided with a Personal Identification Number (PIN) which will be required to remove the freeze from your Plaid Check consumer file. </p>
            <p>${defaultCRAFormIntro}</p>`,
    ...defaultCRAFormFields,
  },
};
